<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Sponsored: "Indicados",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Plan: "Plano",
      Binary: "Binário",
      Status: "Status",
      Qualified: "Qualificado",
      Unqualified: "Não Qualificado",
      Left: "Esquerdo",
      Right: "Direito",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Sponsored: "Patrocinados",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Plan: "Plano",
      Binary: "Binario",
      Status: "Status",
      Qualified: "Calificado",
      Unqualified: "No Cualificado",
      Left: "Izquierdo",
      Right: "Derecho",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      table: {
        heade: [
          "",
          "Username",
          "Name",
          "Date",
          "Cellphone",
          "Plan",
          "Status",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  mounted() {
    api
      .get("network/sponsored")
      .then((response) => {
        if (response.data.status == "success") {
          this.table.body = response.data.list;
          this.statData = [
            {
              icon: "bx bx-user",
              title: "Total",
              value: response.data.total,
            },
          ];
        }
      })
      .catch((error) => {
        this.table.errored = error;
      })
      .finally(() => {
        this.table.loading = false;
        if (this.table.body == "" || this.table.body == null) {
          this.table.empty = true;
        }
      });
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Sponsored") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row === 'avatar'">
                        <img
                          v-if="data"
                          class="rounded-circle header-profile-user"
                          :src="data"
                          alt=""
                          style="width: 40px; height: 40px"
                        />
                        <img
                          v-else
                          class="rounded-circle header-profile-user"
                          :src="
                            'https://ui-avatars.com/api/?background=AC1450&color=fff&name=' +
                            td.name
                          "
                          alt=""
                          style="width: 40px; height: 40px"
                        />
                      </div>
                      <div v-else-if="row == 'package'">
                        <span class="badge badge-soft-dark font-size-12">{{
                          data
                        }}</span>
                      </div>
                      <div v-else-if="row === 'monthly'">
                        <span
                          v-if="data === 'inactive'"
                          class="badge badge-soft-danger font-size-12"
                          >INATIVO</span
                        >
                        <span
                          v-else-if="data === 'active'"
                          class="badge badge-soft-success font-size-12"
                          >ATIVO</span
                        >
                      </div>
                      <div v-else-if="row == 'graduation'">
                        <img
                          v-if="data.image"
                          class="rounded-circle header-profile-user"
                          :src="
                            require('@/assets/images/graduations/' + data.image)
                          "
                          alt=""
                          style="width: 40px; height: 40px"
                        />
                        <img
                          v-else
                          class="rounded-circle header-profile-user"
                          src="@/assets/images/logo-icon.png"
                          alt=""
                          style="width: 40px; height: 40px"
                        />
                        <span class="badge font-size-12">{{ data.name }}</span>
                      </div>
                      <div v-else-if="row === 'status'">
                        <span
                          v-if="data === 'Pending'"
                          class="badge badge-soft-warning font-size-12"
                          >{{ t(data) }}</span
                        >
                        <span
                          v-else-if="data === 'Approved'"
                          class="badge badge-soft-success font-size-12"
                          >{{ t(data) }}</span
                        >
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
